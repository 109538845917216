@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.detail-heading {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 21px !important;
  line-height: 31px !important;
  letter-spacing: -0.25px !important;
  color: #003057;

}

.detail-title {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 40px !important;
  line-height: 44px !important;
  letter-spacing: -1px !important;
  color: #003057;
}

.detail-queue-name {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: -0.1px !important;
  color: #003057;
}

.detail-key {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #003057;
}

.detail-value {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: -0.1px !important;
  color: #505759;
}

.detail-query {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 26px !important;
  letter-spacing: -0.1px !important;
  color: #000000;
}

.detail-component-card {
  padding: 10px !important;
  border-radius: 15px !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25) !important;
}

.detail-soft-warning {
  background-color: #ecf9ff !important;
  border-radius: 10px !important;
  border: 1px !important;
  border-color: #B5D1DC !important;
}

.navigator{
  color: #000000;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 19.07px;
  letter-spacing: 0.8px;
}

@media (max-width: 600px) {
  .details-avatar {
    width: 15vw !important;
    height: 15vw !important;
  }
}

.button-text {
  font-size: 11 !important;
  text-transform: none !important;
  color: #003057 !important;
}

.card-role-select {
  border: 1px solid !important;
  border-radius: 30px !important;
  border-color: #003057 !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25) !important;
}

.text-role-select {
  font-family: 'Open Sans' !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  letter-spacing: -0.1px !important;
  color: #003057 !important;
}

.text-role-queue {
  font-family: 'Open Sans' !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  letter-spacing: -0.1px !important;
  color: #003057 !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}